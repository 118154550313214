import React from 'react';
import styled from '@emotion/styled';

import usePriceLevels from '../hooks/use-pricelevels';
import data from '../../content/data/pricing/FeaturesTable.yml';

// const NON_CONTENT_ROWS = 1;
const LEVEL_COLUMNS = 4;  // TODO: make dynamic

const COLORS = {
    bronze: "#CD7F32",
    silver: "#AAA9AD",
    gold: "#E5C100",
    diamond: "#00BDDE",
};

function FeaturesTable () {
    const price_levels = usePriceLevels();

    return (
        <GRID id="features">
            <LEGEND_CONTAINER>
                <LEGEND>Features</LEGEND>
                <LevelContainer price_levels={price_levels} />
            </LEGEND_CONTAINER>
            <FeatureRows price_levels={price_levels} />
        </GRID>
    );
}

function LevelContainer({ price_levels }) {
    return (
        <LEVEL_CONTAINER>
            {price_levels.map(
                function showLevels(level) {
                    return (
                        <LEVEL color={level}>{level}</LEVEL>
                    );
                }
            )}
        </LEVEL_CONTAINER>
    );
}

function FeatureRows({ price_levels }) {
    return (
        Object.entries(data).map(
            function createRow(feature) {
                return (
                    <ROW>
                        <FEATURE>
                            <NAME>{feature[0]}</NAME>
                            <P dangerouslySetInnerHTML={{__html: feature[1]['desc']}} />
                            <MobileLevels price_levels={price_levels} feature={feature} />
                        </FEATURE>
                        <DesktopCheckContainer feature={feature} />
                    </ROW>
                );
            }
        )
    );
}

function MobileLevels({ price_levels, feature }) {
    return (
        <M_LEVELS>
            {price_levels.map(
                function showLevelsMobile(level) {
                    return (
                        <M_LEVEL>{level}</M_LEVEL>
                    );
                }
            )}
            {feature[1]['include'].map(
                function createLevelMatrix(include, index) {
                    return (
                        <CHECK color={index}>
                            {typeof include == 'string' ? include :
                                include ? <>✔︎</> : <span>&times;</span>}
                        </CHECK>
                    );
                }
            )}
        </M_LEVELS> 
    );
}

function DesktopCheckContainer({ feature }) {
    return (
        <CHECK_CONTAINER>
            {feature[1]['include'].map(
                function createLevelMatrix(include, index) {
                    return (
                        <DESKTOP>
                            <CHECK color={index}>
                                {typeof include == 'string' ? include :
                                    include ? <>✔︎</> : <span>&times;</span>}
                            </CHECK>
                        </DESKTOP>
                    );
                }
            )}
        </CHECK_CONTAINER>
    );
}

const GRID = styled('section')`
    width: 80%;
    margin: 100px auto;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;

    @media (max-width: 915px) {
        width: 90%;
    }

    @media (max-width: 735px) {  // smaller than tablet
        display: flex;
        flex-direction: column;
        width: 100%;
    }
`;

const LEGEND_CONTAINER = styled('div')`
    display: grid;
    grid-template-columns: 3fr 4fr;

    @media (max-width: 735px) {  // smaller than tablet
        border-bottom: 1px solid rgb(204, 204, 204);
    }
`;

const LEGEND = styled('h3')`
    // had to make this an h3 because section styling was screwing it up
    font-size: 64px;
    font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;
    padding-top: 0; // think blog css is conflicting
    padding-left: 20px;

    @media (max-width: 915px) {
        font-size: 50px !important;
    }
`;

const LEVEL_CONTAINER = styled('div')`
    display: grid;
    grid-template-columns: repeat(${LEVEL_COLUMNS}, 1fr);

    @media (max-width: 735px) {  // smaller than tablet
        display: none;
    }
`;

const LEVEL = styled('div')`
    text-align: center;
    align-self: center;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 1.3rem;
    color: ${prop => COLORS[prop.color]};
    border-bottom: 3px solid ${prop => COLORS[prop.color]};
    margin: 0 15px;

    @media (max-width: 915px) {
        font-size: 1.1rem;
    }

    @media (max-width: 735px) {  // smaller than tablet
        display: none;
    }
`;

const ROW = styled('div')`
    display: grid;
    grid-template-columns: 3fr 4fr;
    grid-template-rows: 1fr;
    border-radius: 10px;
    
    &:hover {
        background-color: #f5f5f5;
    }

    @media (max-width: 735px) {  // smaller than tablet
        display: flex;

        &:hover {
            background-color: inherit;
        }
    }
`;

const FEATURE = styled('div')`
    padding: 25px 20px;
    
    @media (max-width: 735px) {  // smaller than tablet
        border-bottom: 1px solid rgb(204, 204, 204);
        border-radius: 0;
        width: 100%;
    }
`;

const NAME = styled('div')`
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 5px;
`;

const P = styled('div')`  // div for now until I figure out 'p !important' conflict
    font-weight: 400;
    font-size: 14px;
    line-height: 1.1rem; // think blog css is conflicting
    margin-bottom: 0;
`;

const M_LEVELS = styled('div')`
    display: none;

    @media (max-width: 735px) {  // smaller than tablet
        display: grid;
        grid-template-columns: repeat(${LEVEL_COLUMNS}, auto);
        padding-top: 30px;
    }
`;

const M_LEVEL = styled('div')`
    align-self: center;
    text-transform: capitalize;
    font-weight: 500;
    font-size: 14px;
`;

const DESKTOP = styled('div')`
    align-self: center;

    @media (max-width: 735px) {  // smaller than tablet
        display: none;
    }
`;

const CHECK_CONTAINER = styled('div')`
    display: grid;
    grid-template-columns: repeat(4, 1fr);  // TODO make dynamic
    grid-template-rows: 1fr;
`;

const CHECK = styled('div')`
    text-align: center;
    font-size: 20px;
    color: ${prop => Object.values(COLORS)[prop.color]};

    span {
        color: rgb(204, 204, 204);
        font-weight: 400;
        font-size: 1.5rem;
    }

    @media (max-width: 735px) {  // smaller than tablet
        text-align: left;
        ${'' /* font-size: 14px; */}
    }
`;

export default FeaturesTable;