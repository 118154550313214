import React from 'react';
import styled from '@emotion/styled';

function pageTitle({ title }) {
    return (
        <H1>{title}</H1>
    );
}

const H1 = styled('h1')`
    text-align: center;
    font-size: 80px;
    padding: 65px 0;
    letter-spacing: 2px;
    margin-bottom: 0;  // conflicting with main style sheet
    font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;

    @media (max-width: 400px){
        font-size: 60px;
    }
`;

export default pageTitle;