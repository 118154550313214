import React, { useState } from 'react';
import styled from '@emotion/styled';

import data from '../../content/data/pricing/FAQ.yml';

function FAQ() {  
    return (
        <FAQ_BOX id="faq">
            <TITLE>Frequently Asked Questions</TITLE>
            {Object.entries(data).map(
                function createQA(qa, index) {
                    return (
                        <QA_ITEM qa={qa} key={index} />
                    );
                }
            )}
        </FAQ_BOX>
    );
}

function QA_ITEM({ qa }) {
    const [ isOpen, toggleState ] = useState(false);

    return (
        <QA_ROW show={isOpen} onClick={() => toggleState(!isOpen)}>
            <Q_BOX>
                <QUESTION>{qa[0]}</QUESTION>
                <TOGGLE show={isOpen}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="Icon__StyledSVG-sc-14uxrhm-0 lhKFTg">
                        <path d="M 17 7 L 7 17 M 7 7 L 12.03 12.03 L 17 17" stroke={isOpen ? '#000' : '#999999'} fill="transparent" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" />
                    </svg>
                </TOGGLE>
            </Q_BOX>
            <A_BOX show={isOpen}>
                <ANSWER dangerouslySetInnerHTML={{__html: qa[1]}} />
            </A_BOX>
        </QA_ROW>
    );
}

const FAQ_BOX = styled('div')`
    ${'' /* padding: 100px 100px; */}
    width: 80%;
    padding-bottom: 5rem;
    margin: 0 auto;
    overflow: hidden;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;

    @media (max-width: 735px) {
        width: 100%;
    }
`;

const TITLE = styled('div')`
    font-family: Nobile,Helvetica Neue,Helvetica,Arial,sans-serif;
    font-size: 64px;
    font-weight: 600;
    letter-spacing: -2px;
    line-height: 1.1;
    border-bottom: 1px solid rgb(204, 204, 204);
    padding-bottom: 20px;
    margin-bottom: 20px;

    @media (max-width: 735px) {
        font-size: 50px;
        padding: 0 20px;
    }
`;

const QA_ROW = styled('div')`
    background: ${props => props.show ? '#f5f5f5' : 'inherit'};
    padding: 20px 30px;
    margin-bottom: 3px;
    border-radius: 16px;

    &:hover {
        background: #f5f5f5;
    }

    @media (max-width: 735px) {
        padding: 20px 10px;
        margin-left: 15px;
        margin-right: 15px;
    }
`;

const Q_BOX = styled('div')`
    display: flex;
    justify-content: space-between;
    cursor: pointer;
`;

const QUESTION = styled('div')`
    font-size: 20px;
    font-weight: 600;
`;

const TOGGLE = styled('div')`
    width: 24px;
    height: 24px;
    margin-left: 20px;
    flex-shrink: 0;
    ${'' /* color: ${props => props.show ? 'inherit' : '#999999'}; */}
    transition: transform 0.3s;
    transform: rotate(${props => props.show ? '0' : '45'}deg);
`;

const A_BOX = styled('div')`
    overflow: hidden;
    display: ${props => props.show ? 'inherit' : 'none'};
    padding-top: 18px;
`;

const ANSWER = styled('div')`
    font-size: 17px;
    line-height: 1.7;
    margin-bottom: 0; // conflicting with blog
    animation: fadeIn ease .5s;
    @keyframes fadeIn {
        0% {opacity:0;}
        100% {opacity:1;}
    }
`;

export default FAQ;

// visiblity vs. display none? Maybe I should use visibility because of accessibility