import React from 'react';
import styled from '@emotion/styled';

import Checkout from '../Checkout';
import data from '../../../content/data/pricing/CoachingLevelCard.yml';

function CoachingLevelCard({ level }) {
    const { colors, popular, prevPrice, price, priceLabel, bullets, cta, item } = data[level];

    return (
        <CARD_DIV color={colors[0]}>
            {popular && <POPULAR>popular</POPULAR>}
            <HEADER color={colors[0]}>
                <TITLE color={colors[1]}>{level}</TITLE>
                {prevPrice && <PREVPRICE>{prevPrice}</PREVPRICE>}
                <PRICE color={colors[1]}>
                    {price}
                    <PRICELABEL>{priceLabel}</PRICELABEL>
                </PRICE>
            </HEADER>
            <BULLETS>
                {bullets.map(bullet => <li dangerouslySetInnerHTML={{__html: bullet}}></li>)}
            </BULLETS>
            <Checkout cta={cta} item={item} />
        </CARD_DIV>
    );
}

const CARD_DIV = styled('div')`
    ${'' /* flex: 0 1 calc(25% - 1em); */}
    ${'' /* min-width: 270px; */}
    min-width: 30ch;
    margin: 15px 0;
    padding-bottom: 43px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    border-bottom: 1px solid ${prop => prop.color};
    border-right: 1px solid ${prop => prop.color};
    border-left: 1px solid ${prop => prop.color};
    border-radius: 10px;

    @media (max-width: 1250px) {
        width: 43%;
    }

    @media (max-width: 600px) {
        width: 85%;
    }
`;

const POPULAR = styled.div`
    position: absolute;
    top: -7px;
    background: #FF0080;
    color: #fff;
    text-transform: uppercase;
    z-index: 2;
    padding: 2px 5px;
    font-size: 9px;
    border-radius: 2px;
    right: 10px;
    font-weight: 700;
`;

const HEADER = styled.div`
    height: 143px;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    background-color: ${prop => prop.color};

    border-radius: 10px 10px 0 0;
`;

const TITLE = styled.h3`
    ${'' /* height: 34px; */}
    width: 100%;
    padding: 5px 0;
    font-size: 20px;
    background-color: ${prop => prop.color};

    margin: 0;
    color: white;
    text-align: center;
    text-transform: uppercase;

    border-radius: 10px 10px 0 0;
`;

const PREVPRICE = styled.span`
    position: absolute;
    top: 50%;
    margin-left: ${props => props.children.length < 5 ? '15' : '12'}%;
    font-size: 21px;
    font-weight: 400;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg' preserveAspectRatio='none'%3e%3cline x1='0' y1='80' x2='100' y2='30' stroke-width='8' stroke='%230B2437'/%3e%3c/svg%3e");
    background-size: 100% 100%;
`;

const PRICE = styled.div`
    font-size: 20px;
    color: white;
    text-align: center;

    position: absolute;
    bottom: -25px;
    background: ${prop => prop.color};
    height: 96px;
    width: 100px;
    margin: 0 auto;
    left: 0;
    right: 0;
    overflow: hidden;
    border-radius: 50%;
    border: 5px solid #fff;
    line-height: 80px;
    font-size: 28px;
    font-weight: 700;
`;

const PRICELABEL = styled.span`
    color: white;
    text-transform: uppercase;
    position: absolute;
    font-size: 9px;
    bottom: -14px;
    // TODO redesign so PRICE and PRICELABEL are two divs inside the circle div so following is unnecessary
    left: ${props => props.children.length < 10 ? '18' : '15'}px;
    font-weight: 525;
`;

const BULLETS = styled.ul`
    margin-bottom: 30px;
    width: 100%;
    list-style: none;
    font-size: 0.9rem;
    
    li {
        padding: 10px 10px;
        text-align: center;
        border-top: 1px solid #E5E5E5;

        margin-bottom: 0;  // need this for li in layout.css
    }

    li:first-child {
        margin-top: 10px;
        border-top: none;
    }
`;

export default CoachingLevelCard;