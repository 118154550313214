import React from 'react';
import { loadStripe } from "@stripe/stripe-js";
import styled from '@emotion/styled';

const stripePromise = loadStripe('pk_live_49GL3JIRcfcvqEBbfbapRouO');

const Checkout = ({ cta, item }) => {
	const redirectToCheckout = async event => {
		event.preventDefault()
		const stripe = await stripePromise
		const { error } = await stripe.redirectToCheckout({
			items: [{ plan: item, quantity: 1 }],
			successUrl: `https://tonybronzo.com/success`,
			cancelUrl: `https://tonybronzo.com/pricing`,
		})
		if (error) {
			console.warn("Error:", error)
		}
	}

	return (
		<BUTTON onClick={redirectToCheckout}>
			{cta}
		</BUTTON>
	)
}

const BUTTON = styled.button`
	font-size: 15px;
	font-weight: 700;
	text-align: center;
	text-transform: uppercase;
	color: #fff;
	outline: none;
	padding: 12px 7px;
	${'' /* box-shadow: 2px 5px 10px rgba(0,0,0,.1); */}
	background-color: #323232;
	letter-spacing: 1.5px;
	width: 150px;
	border-radius: 50px;
	cursor: pointer;

	&:hover {
		background-color: #646363;
	}
`;

export default Checkout
