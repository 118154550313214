import React from "react"
import styled from '@emotion/styled';

import SEO from "../components/seo"
import PageTitle from '../components/PageTitle';
import BuyBox from '../components/BuyBox';
import FeaturesTable from "../components/FeaturesTable";
import FAQ from '../components/FAQ';

const PricingPage = () => (
  <>
    <SEO title="Pricing" />
    <CENTER>
      <PageTitle title="Pricing"/>
      <BuyBox />
      <FeaturesTable />
      <FAQ />
    </CENTER>
  </>
)

const CENTER = styled('main')`
  width: 100vw;
  margin: 0 auto;
  background: #FFF;

	// image switches to fullscreen
	@media (max-width: 500px) {
		width: 100%;
  }
`;

export default PricingPage