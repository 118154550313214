import { graphql, useStaticQuery } from 'gatsby';

function usePriceLevels() {
    const { site: { siteMetadata: { price_levels }}} = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    price_levels
                }
            }
        }
    `);
    
    return price_levels;
};

export default usePriceLevels;

