import React from 'react';
import styled from '@emotion/styled';

import usePriceLevels from '../hooks/use-pricelevels';
import CoachingLevelCard from './CoachingLevelCard/CoachingLevelCard';

function BuyBox() {

    const price_levels = usePriceLevels();
    
    return (
        <OUTER_DIV>
            {price_levels.map(
                function showCard(level) {
                    return (
                        <CoachingLevelCard level={level} />
                    );
                }
            )}
        </OUTER_DIV>
    )
}

const OUTER_DIV = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;

    h3 {
        margin-top: 0;
    }
`;

export default BuyBox;